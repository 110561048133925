import React from 'react';
import './Home.css'; // Import the CSS file
import './Timeline.css'
function Experience() {
    return (
      <section id="experience" className="parallax-section">
      <div className="container">
          <div className="row">
              <div className="wow fadeInUp section-title" data-wow-delay="0.2s">
                  <h2>My Experience</h2>
              </div>

              <ul className="timeline">
                <li>
                  <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                      <h4>Juni Learning</h4>
                      <em>
                          <span>Computer Science Instructor</span>
                          <span> | </span>
                          <span>Remote</span>
                          <span> | </span>
                          <span>June 2024 - Present</span>
                      </em>
                      <p>
                          <ul style={{ listStyleType: 'disc' }}>
                              <li>Provided 100+ hours of one-on-one instruction in AP Computer Science material, Web-Development, Java, Javascript, and Python for middle and high school students.</li>
                              <li>Covered object-oriented programming principles, algorithms, and data structures using individualized teaching methods and a project-based curriculum.</li>
                              <li>Guided students in building presentable coding projects and instilled sound programming practices, including code style, organization, and documentation techniques.</li>
                          </ul>
                      </p>
                  </div>
              </li>

              <li>
                  <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                      <h4>MindsDB - AI Development Platform</h4>
                      <em>
                          <span>Open Source Software Development</span>
                          <span> | </span>
                          <span>Remote</span>
                          <span> | </span>
                          <span>April 2024 - Present</span>
                      </em>
                      <p>
                          <ul style={{ listStyleType: 'disc' }}>
                              <li>Implemented a Financial Modeling Prep API integration for the MindsDB platform, enabling users to use Machine Learning for financial analysis and predictions (Python).</li>
                              <li>Key features include connecting to the Financial Modeling API, querying the database, and loading daily stock chart information for specific symbols in MindSDB Cloud Editor using MindsDB-SQL commands.</li>
                              <li>Contributed to the codebase by developing comprehensive tests for existing integrations, refining documentation, and resolving bugs using Python.</li>
                          </ul>
                          
                      </p>
                  </div>
              </li>

              <li>
                  <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                      <h4>Bear Hack UCR</h4>
                      <em>
                          <span>Software Engineer</span>
                          <span> | </span>
                          <span>Remote</span>
                          <span> | </span>
                          <span>August 2023 - June 2024</span>
                      </em>
                      <p>
                          <ul style={{ listStyleType: 'disc' }}>
                              <li>Resolved weekly GitHub issues for the UCR Bear Hack website and admin backend for all UCR hackathon portals.</li>
                              <li>Worked with ReactJS and Tailwind CSS for frontend development, and Firebase framework for backend operations, including user sign-ups and authentication.</li>
                              <li>Acquired proficiency in Postman to test HTTP routes, ensuring the website’s robust functionality.</li>
                              <li>Actively engaged in brainstorming sessions and design discussions to achieve a visually appealing and responsive landing page.</li>
                          </ul>
                      </p>
                  </div>
              </li>

              <li>
                  <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                      <h4>Clearspot.ai</h4>
                      <em>
                          <span>AI/ML Intern</span>
                          <span> | </span>
                          <span>Remote</span>
                          <span> | </span>
                          <span>July 2023 - September 2023</span>
                      </em>
                      <p>
                          <ul style={{ listStyleType: 'disc' }}>
                              <li>Developed object detection models utilizing the YOLO (You-Only-Look-Once) architecture for Edge AI applications in the drone industry, focusing on construction safety and environmental monitoring use cases.</li>
                              <li>Performed data collection, model training, and comparison of performance benchmarks, achieving minimum accuracy of 91%.</li>
                              <li>Utilized PyTorch, Python, and C++ for model implementation and OpenCV to pre-process video streams.</li>
                              <li>Presented in-depth market research on competitors within the Edge Computing Computer Vision industry, advising the startup on realistic growth opportunities.</li>
                          </ul>
                      </p>
                  </div>
              </li>

              <li>
                <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                    <h4>EcoCAR Team at UC Riverside</h4>
                    <em>
                        <span>Competition Connected and Automated Vehicle Subteam - Technical Intern</span>
                        <span> | </span>
                        <span>Nov 2022 - Dec 2023</span>
                    </em>
                    <p>
                        <ul style={{ listStyleType: 'disc' }}>
                            <li>Implemented a CI/CD pipeline using Github Actions to automate testing of MATLAB scripts and maintain release documentation.</li>
                            <li>Collaborated closely with CAV team leads on Year 1-2 deliverables including the Compute Design Report and Safety Report.</li>
                            <li>Gained experience in modeling multi-sensor perception data using Lidar, Radar, and Altera sensors.</li>
                            <li>Volunteered in K-12 outreach events and UCR Tabling Events to recruit new members for Year 2 of the EcoCAR competition.</li>
                        </ul>
                    </p>
                </div>
              </li>
              {/* <li>
                  <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                      <h4>UCSD Comp Sci and Engineering Society</h4>
                      <em>
                          <span>Open Source Developer</span>
                          <span> | </span>
                          <span>Remote</span>
                          <span> | </span>
                          <span>July 2023 - September 2023</span>
                      </em>
                      <p>
                          <ul style={{ listStyleType: 'disc' }}>
                              <li>Developed object detection models utilizing the YOLO (You-Only-Look-Once) architecture for Edge AI applications in the drone industry, focusing on construction safety and environmental monitoring use cases.</li>
                              <li>Performed data collection, model training, and comparison of performance benchmarks, achieving minimum accuracy of 91%.</li>
                              <li>Utilized PyTorch, Python, and C++ for model implementation and OpenCV to pre-process video streams.</li>
                              <li>Presented in-depth market research on competitors within the Edge Computing Computer Vision industry, advising the startup on realistic growth opportunities.</li>
                          </ul>
                      </p>
                  </div>
              </li> */}
              </ul>
          </div>
      </div>
  </section>
);
  }
  
  export default Experience;