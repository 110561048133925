import logo from './logo.svg';
import './App.css';

import Home from './components/Home';
import AboutMe from './components/AboutMe';  // Adjust the path as necessary
import Navbar from './components/Navbar';
import Experience from './components/Experience';
// import Projects from './components/Projects';
import Education from './components/Education';

function App() {
  return (
      <div
      id="main-div"
      style={{
        position: 'relative',
        width: '100%', // Full width
        background: 'linear-gradient(to right, #fff, #ffe6f0)', // Slight pink gradient
        display: 'flex',
        flexDirection: 'column', // Aligns everything from top to bottom
        justifyContent: 'flex-start', // Aligns content to the top
        alignItems: 'left', // Centers content horizontally
        padding: '20px 0', // Optional padding for main content
      }}
    >
      <Navbar/>
      <Home/>
      <AboutMe />
      <Education/>
      <Experience/>
    </div>



  );
}


export default App;
