import React from 'react';
import './Home.css'; // Import the CSS file

function Navbar() {
    return (
        <div
        style={{
          display: 'flex',
          gap: '20px',
          padding: '10px 20px',
          justifyContent: 'flex-end',
          backgroundColor: 'transparent', // Transparent background
        }}
      >
        <a href="#about-me" style={{ textDecoration: 'none', color: '#333', fontFamily: "'Oswald', sans-serif", fontSize: '16px', letterSpacing: '1px', fontWeight: '500' }}>
            About Me
          </a>
          <a href="#education" style={{ textDecoration: 'none', color: '#333', fontFamily: "'Oswald', sans-serif", fontSize: '16px', letterSpacing: '1px',  fontWeight: '500' }}>
            Education
          </a>
          <a href="#experience" style={{ textDecoration: 'none', color: '#333', fontFamily: "'Oswald', sans-serif", fontSize: '16px', letterSpacing: '1px',  fontWeight: '500' }}>
            Experiences
          </a>
      </div>
    );
  }
  
  export default Navbar;