import React from 'react';
import './Home.css'; // Import the CSS file
import riyana from '../images/riyana.jpg';

function Home() {
  return (
<div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="row w-100">
        {/* Image Column */}
        <div className="col-md-4 d-flex justify-content-center">
          <img 
            src={riyana} 
            alt="Profile" 
            className="img-fluid rounded-circle" 
            style={{ width: '400px', height: '400px' }}
          />
        </div>
        {/* Text Column */}
        <div className="col-md-8 d-flex flex-column justify-content-center">
          <h1>Hello, I'm Riyana. 👋</h1>
          <h2>Welcome to My Website!</h2>
        </div>
      </div>
    </div>
  );
}

export default Home;
