import React from 'react';
import './AboutMe.css'; // Import CSS for styling

const AboutMe = () => {
  return (
    <section id="about-me" className="parallax-section">
      <div className="container">
        <div className = "row">
          <div className="col-md-4">
            <ul className="info-list">
              <li>
                <strong>NAME:</strong>
                <span> Riyana Dutta</span>
              </li>
              <li>
                <strong>PRONOUNS:</strong>
                <span> She/Her/Hers</span>
              </li>
              <li>
                <strong>SCHOOL:</strong>
                <span> University of California, San Diego</span>
              </li>
              <li>
                <strong>AGE:</strong>
                <span> 20 Years</span>
              </li>
              <li>
                <strong>HOMETOWN:</strong>
                <span> Bay Area</span>
              </li>
              <li>
                <ul className="social-icon">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/your-profile"
                      className="fa fa-linkedin"
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/RiyanaD"
                      className="fa fa-github"
                    ></a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="col-md-8">
            <div className="about-text">
              <p>
                I am a student at UC San Diego pursuing a B.S. in Computer Science, Class of 2026.
                I have been studying computer science since high school and am
                comfortable coding in C, C++, Java, Python, HTML, CSS, Javascript, React,
                and more. I have experience in both Software Engineering and AI/ML development. I am highly interested in gaining industry experience in Full-Stack Software Engineering, Generative AI, Cloud Computing, and DevOps, and I am open to roles within these fields!
              </p>

              <p>
                I also enjoy
                contributing to open-source projects and exploring new technologies! Currently I am working as a Computer Science Instructor at Juni Learning and getting hands-on experience with the open source project MindsDB. In my free time,
                I love to dance, read, attend yoga classes and try different foods!
              </p>
            </div>

            {/* <div className="about-btns">
              <a
                target="_blank"
                rel="noreferrer"
                href="path/to/Resume.pdf"
                className="btn-custom btn-color"
                download="Riyana_Dutta_Resume"
              >
                Download Resume
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;

