import React from 'react';
import './Home.css'; // Import the CSS file
import './Timeline.css'

function Education() {
    return (
        <section id="education" className="parallax-section">
            <div className="container">
                <div className="row">
                    <div className="wow fadeInUp section-title" data-wow-delay="0.2s">
                        <h2>My Education</h2>
                    </div>

                    <ul className="timeline">
                        <li>
                            <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                                <h4>University of California, San Diego</h4>
                                <em>
                                    <span>B.S. Computer Science</span>
                                    <span> | </span>
                                    <span>September 2024 - Expected Graduation: June 2026</span>
                                </em>
                                <p>
						<b>Relevant coursework:</b> Computer Systems and Software Tools, Introduction to Numerical Analysis
					</p>
                            </div>
                        </li>

                        <li>
                            <div className="wow fadeInUp timeline-content" data-wow-delay="0.1s">
                                <h4>University of California, Riverside</h4>
                                <em>
                                    <span>B.S. Computer Science</span>
                                    <span> | </span>
                                    <span>September 2022 - June 2024</span>
                                </em>
                                <p>
                                    <b>Awards:</b> Chancellor's Honors List - Freshman and Sophomore Year
                                </p>
                                <p>
						<b>Relevant coursework:</b> Object Oriented Programming in C++, Data Structures and Algorithms, Software Construction, Logic Design, Intermediate Data Structures and Algorithms, Machine Organization and Assembly Language Programming, Discrete Structures, Automata and Formal Theory Language, Design of Operating Systems, Applied Linear Algebra, Physics 
					</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Education;
